import React, { useState, useEffect } from "react"
import axios from "axios"

import { ArrowRight, ChevronRight, ChevronLeft } from "react-feather"

import Modal from "../../components/Modal/index"

import Steps from "../../components/MultiSteps/index"

import Input from "../../components/Input/Input"

const InviteMemberModal = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [loadingCompleteText, setLoadingCompleteText] = useState("")
  const [prevSelectedStep, setPrevSelectedStep] = useState(1)
  const [selectedStep, setSelectedStep] = useState(1)
  const [goBack, setGoBack] = useState(false)

  // Fields to send to airtable
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState("")
  const [noOfRequests, setNoOfRequests] = useState("")
  const [noOfRequestsError, setNoOfRequestsError] = useState("")
  const [company, setCompany] = useState("")
  const [companyError, setCompanyError] = useState("")
  const [role, setRole] = useState("")
  const [roleError, setRoleError] = useState("")
  const [purpose, setPurpose] = useState("")
  const [purposeError, setPurposeError] = useState("")

  useEffect(() => {
    // If modal is not opened
    if (!props.open) {
      setIsLoading(false)
      setIsComplete(false)
    }
  }, [props.open])

  useEffect(() => {
    if (props.email) {
      setEmail(props.email)
    }
  }, [props])

  const handleDeactivateAccount = () => {
    setIsLoading(true)
  }

  const onSubmit = e => {
    // Set button state as loading
    setIsLoading(true)
    setIsButtonLoading(true)

    let currentDate = new Date();
    let finalDate = currentDate.getDate() +'-'+ (currentDate.getMonth() + 1) +'-'+ currentDate.getFullYear() +' '+ currentDate.getHours() +':'+ currentDate.getMinutes() +':'+ currentDate.getSeconds();

    let data = [
      {
        name,
        email,
        requests: noOfRequests,
        role,
        company,
        purpose,
        date: finalDate
      },
    ]

    axios
      .post(
        "https://v1.nocodeapi.com/aide/airtable/qCnnfgIEoqUGxkjJ?tableName=email_form",
        data
      )
      .then(function (response) {
        if (response.data) {
          if (200 === response.status) {
            // Success, show notification and close the modal
            setIsButtonLoading(false)

            setLoadingCompleteText(`Request sent successfully`)
            setIsLoading(false)
            setIsComplete(true)

            setTimeout(function () {
              // Reset all of the errors
              setName("")
              setNameError("")
              setEmail("")
              setEmailError("")
              setRole("")
              setRoleError("")
              setNoOfRequests("")
              setNoOfRequestsError("")
              setPurpose("")
              setPurposeError("")
              setCompany("")
              setCompanyError("")

              setPrevSelectedStep(1)
              setSelectedStep(1)

              props.modalUpdatedSucess()
            }, 1000)
          }
        }
      })
      .catch(function (error) {
        console.log(error)
        setLoadingCompleteText(
          "Something went wrong, please try again later on"
        )
      })
      .finally(function () {
        // Make button active again since either call succeeded or failed
        setIsButtonLoading(false)
        setIsLoading(false)
        setIsComplete(true)
      })
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={e => {
          // Reset all of the errors
          setName("")
          setNameError("")
          setEmail("")
          setEmailError("")
          setRole("")
          setRoleError("")
          setNoOfRequests("")
          setNoOfRequestsError("")
          setPurpose("")
          setPurposeError("")
          setCompany("")
          setCompanyError("")

          setPrevSelectedStep(1)
          setSelectedStep(1)

          props.onClose(e)
        }}
        modalStyle={{
          maxWidth: 420,
          minWidth: 300,
        }}
      >
        <Modal.Header closeIcon={true} onCloseIconClick={props.onClose}>
          <h3 style={{ margin: 0, fontSize: 22 }}>
            Request a Product Demo
          </h3>
        </Modal.Header>
        <Modal.Content>
          {loadingCompleteText && <p>{loadingCompleteText}</p>}
          {!isComplete && (
            <>
              <p>
                Please fill the form below to help us
                understand your use-case and streamline your support process!
              </p>

              <Steps
                currentStep={selectedStep}
                prevSelectedStep={prevSelectedStep}
                backStep={goBack}
              >
                <Steps.Step>
                  <div style={{ minHeight: 360 }}>
                    <div style={{ marginBottom: 20 }}>
                      <Input
                        className="modal-field"
                        type="text"
                        name="name"
                        placeholder="enter your name"
                        autoFocus={true}
                        style={{
                          minWidth: "100%",
                        }}
                        value={name}
                        haserror={nameError.length > 0 ? true : false}
                        onChange={e => {
                          setName(e.target.value)
                        }}
                      />

                      {nameError && (
                        <span className="email-error">{nameError}</span>
                      )}
                    </div>
                    <div style={{ marginBottom: 20 }}>
                      <Input
                        className="modal-field"
                        type="email"
                        placeholder="enter your email"
                        style={{
                          minWidth: "100%",
                        }}
                        value={email}
                        haserror={emailError.length > 0 ? true : false}
                        onChange={e => {
                          setEmail(e.target.value)
                        }}
                      />

                      {emailError && (
                        <span className="email-error">{emailError}</span>
                      )}
                    </div>
                    <div>
                      <Input
                        className="modal-field"
                        type="number"
                        placeholder="expected requests per day"
                        style={{
                          minWidth: "100%",
                        }}
                        value={noOfRequests}
                        haserror={noOfRequestsError.length > 0 ? true : false}
                        min="0"
                        onChange={e => {
                          setNoOfRequests(e.target.value)
                        }}
                      />

                      {noOfRequestsError && (
                        <span className="email-error">{noOfRequestsError}</span>
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 30,
                      }}
                    >
                      <button
                        className={`access-button darkens ${
                          false ? "active" : ""
                        }`}
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          setNameError("")
                          setNoOfRequestsError("")
                          setEmailError("")
                          if (
                            name.length === 0 ||
                            noOfRequests.length === 0 ||
                            email.length === 0 ||
                            !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(
                              email
                            )
                          ) {
                            if (name.length === 0) {
                              setNameError("name is required")
                            }
                            if (noOfRequests.length === 0) {
                              setNoOfRequestsError("no of requests is required")
                            }
                            if (email.length === 0) {
                              setEmailError("email is required")
                            } else if (
                              !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(
                                email
                              )
                            ) {
                              setEmailError("email is invalid")
                            }
                          } else {
                            // All good, move to second step
                            setPrevSelectedStep(selectedStep)
                            setGoBack(false)
                            setSelectedStep(2)
                          }
                        }}
                      >
                        Next{" "}
                        <ChevronRight
                          className="access-chevron"
                          style={{ top: 0 }}
                        />
                      </button>
                    </div>
                  </div>
                </Steps.Step>
                <Steps.Step style={{ minHeight: 360 }}>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      className="modal-field"
                      type="text"
                      name="company"
                      placeholder="company"
                      autoFocus={true}
                      style={{
                        minWidth: "100%",
                      }}
                      value={company}
                      haserror={companyError.length > 0 ? true : false}
                      onChange={e => {
                        setCompany(e.target.value)
                      }}
                    />

                    {companyError && (
                      <span className="email-error">{companyError}</span>
                    )}
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      className="modal-field"
                      type="text"
                      placeholder="role"
                      style={{
                        minWidth: "100%",
                      }}
                      value={role}
                      haserror={roleError.length > 0 ? true : false}
                      onChange={e => {
                        setRole(e.target.value)
                      }}
                    />

                    {roleError && (
                      <span className="email-error">{roleError}</span>
                    )}
                  </div>
                  <div>
                    <textarea
                      className={`modal-field modal-textarea ${
                        purposeError.length > 0 ? "error" : ""
                      }`}
                      placeholder="why you want to use Aide"
                      rows={3}
                      style={{
                        minWidth: "100%",
                        padding: "9px 18px",
                      }}
                      value={purpose}
                      onChange={e => {
                        setPurpose(e.target.value)
                      }}
                    />

                    {purposeError && (
                      <span className="email-error">{purposeError}</span>
                    )}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 30,
                      display: "flex",
                    }}
                  >
                    <button
                      className={`access-button darkens back ${
                        false ? "active" : ""
                      }`}
                      onClick={() => {
                        setGoBack(true)
                        setPrevSelectedStep(selectedStep)
                        setSelectedStep(1)
                      }}
                      style={{ marginLeft: 0 }}
                    >
                      <ChevronLeft
                        className="access-chevron"
                        style={{ top: 0 }}
                      />{" "}
                      Back
                    </button>
                    <button
                      className={`access-button darkens ${
                        false ? "active" : ""
                      }`}
                      style={{ marginLeft: "auto" }}
                      onClick={e => {
                        setCompanyError("")
                        setRoleError("")
                        setPurposeError("")
                        if (
                          company.length === 0 ||
                          role.length === 0 ||
                          purpose.length === 0
                        ) {
                          if (company.length === 0) {
                            setCompanyError("company is required")
                          }
                          if (role.length === 0) {
                            setRoleError("role is required")
                          }
                          if (purpose.length === 0) {
                            setPurposeError("purpose is required")
                          }
                        } else {
                          // All good, move to second step
                          setPrevSelectedStep(selectedStep)
                          setGoBack(false)
                          onSubmit(e)
                        }
                      }}
                    >
                      {isButtonLoading ? (
                        <>Submitting...</>
                      ) : (
                        <>
                          Submit{" "}
                          <ChevronRight
                            className="access-chevron"
                            style={{ top: 0 }}
                          />
                        </>
                      )}
                    </button>
                  </div>
                </Steps.Step>
              </Steps>
            </>
          )}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default InviteMemberModal
