import React, { useEffect, useState } from 'react'

import styles from './MultiStep.module.scss'

const MultiStepContext = React.createContext({
  totalSteps: 0,
  currentStep: 1,
  isSliding: false,
  isSlide: false,
  movingBack: false
})

export const Step = (props) => {
  return (
    <MultiStepContext.Consumer>
      { ({ currentStep, isSliding, isSlide, movingBack }) => {
        return(
          <div
            className={
              props.className
                ? props.className
                : (isSlide ? styles.slide + ((movingBack ? ' ' + styles.movingBack : '') + ( isSliding ? ' ' + styles.sliding : '' )) : '')
            }
            style={props.style}
          >
            {props.children}
          </div>
        )
      } }
    </MultiStepContext.Consumer>
  );
};

const Steps = ({
  currentStep,
  prevSelectedStep,
  backStep,
  ...props
}) => {
  const [ hasSlide, setHasSlide ] = useState(false)
  const [ sliding, setSliding ] = useState(false)
  const [ movingBack, setMovingBack ] = useState(false)
  useEffect(() => {
    // Reset all states
    setMovingBack(false)
    setSliding(false)
    setHasSlide(false)

    // See if current step isn't same as previous
    if(prevSelectedStep !== currentStep) {
      // Add class has slide
      setHasSlide(true)

      // Are we moving back on the form?
      if(backStep) {
        setMovingBack(true)
      }

      // set a timer for delay
      setTimeout(function() {
        setSliding(true)
        // Reset the delyed states
        setTimeout(function() {
          setSliding(false)
          setHasSlide(false)
          setMovingBack(false)
        }, 200)
      }, 100)
    }
  }, [currentStep, prevSelectedStep, backStep])
  return (
    <>
      <MultiStepContext.Provider value={ { currentStep: props.currentStep, totalSteps: props.children.length, isSliding: sliding, isSlide: hasSlide, movingBack: movingBack } }>
        <div className={ styles.steps }>
          { props.children.map((step, index) => (
            <span key={ index } className={ styles.step + ( (index +1) === parseInt(currentStep) ? ' ' + styles.active : '' ) }></span>
          )) }
        </div>
        <div className={ (hasSlide ? styles.stepContainer + ( movingBack ? ' ' + styles.stepContainerBack : '' ) : '') }>
          { currentStep && props.children.map((child, index) => ((index +1) == parseInt(currentStep) ? child : null)) }
        </div>
      </MultiStepContext.Provider>
    </>
  )
}

Steps.Step = Step;

export default Steps;
