import React, { useEffect } from "react"
import { X } from "react-feather"
import ReactModal from "react-modal"

import styles from "./Modal.module.scss"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

export const ModalHeader = props => {
  return (
    <div
      className={props.className ? props.className : styles.ModalHeader}
      style={props.style}
    >
      {props.closeIcon ? (
        <div className={styles.flex}>
          <div>{props.children}</div>
          <div>
            <X
              style={{ cursor: "pointer", width: "16px", opacity: "0.2" }}
              onClick={props.onCloseIconClick}
            />
          </div>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </div>
  )
}

export const ModalContent = props => {
  return (
    <div
      className={props.className ? props.className : styles.modalBody}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

export const ModalFooter = props => {
  return (
    <div className="Modal-Column Modal-Footer" style={props.style}>
      {props.children}
    </div>
  )
}

const Modal = ({ ...props }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      ReactModal.setAppElement("body")
    }
  }, [])

  return (
    <>
      <ReactModal
        isOpen={props.open}
        className={props.className ? props.className : styles.Modal}
        onRequestClose={props.onClose}
        shouldCloseOnOverlayClick={
          props.shouldCloseOnOverlayClick
            ? props.shouldCloseOnOverlayClick
            : true
        }
        overlayClassName={
          props.overlayClassName ? props.overlayClassName : styles.ModalOverlay
        }
        style={{
          content: {
            ...props.modalStyle,
          },
        }}
        contentLabel="Example Modal1"
      >
        {props.children}
      </ReactModal>
    </>
  )
}

Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Footer = ModalFooter

export default Modal
